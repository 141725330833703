<template>
  <div style="min-height: calc(100vh - 70px)">
<!--    <div style="margin: 10px 0">-->
<!--      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>-->
<!--&lt;!&ndash;      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>&ndash;&gt;-->
<!--&lt;!&ndash;      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>&ndash;&gt;-->
<!--      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>-->
<!--      <el-button type="warning" @click="reset">重置</el-button>-->
<!--    </div>-->

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" size="mid">寄养</el-button>
<!--      <el-popconfirm-->
<!--          class="ml-5"-->
<!--          confirm-button-text='确定'-->
<!--          cancel-button-text='我再想想'-->
<!--          icon="el-icon-info"-->
<!--          icon-color="red"-->
<!--          title="您确定批量删除这些数据吗？"-->
<!--          @confirm="delBatch"-->
<!--      >-->
<!--        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>-->
<!--      </el-popconfirm>-->
      <!-- <el-upload action="http://localhost:9090/salvation/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
    </div>
    <el-card  v-for="item in tableData" :key="item.id" style="margin: 10px 0">
      <div style="display: flex">
        <div style="padding-left: 50px; flex: 1">
          <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
            <span style="font-size: 24px">{{ item.animalName }}</span>
          </div>
          <div style="line-height: 30px">
            <div><b style="margin-right: 10px">时间：</b>{{ item.startTime }}-{{item.endTime}}</div>
            <div v-if="item.deliveryMethod === '商家上门'">
              <b style="margin-right: 10px">方式：</b>{{ item.deliveryMethod }}
              <span style="float: right">
              <b style="margin-right: 10px;">地址：</b>{{ item.address }}
              <b style="margin-right: 10px">电话：</b>{{ item.phone }}
                </span>
            </div>
            <div v-if="item.deliveryMethod === '顾客自送'">
              <b style="margin-right: 10px">方式：</b>{{ item.deliveryMethod }}

            </div>
            <div><b style="margin-right: 10px">金额：</b>{{ item.totalAmount }}</div>
            <div><b style="margin-right: 10px">类型：</b>{{ item.type }}</div>
            <div><b style="margin-right: 10px">品种：</b>{{ item.variety }}</div>
            <div><b style="margin-right: 10px">状态：</b>{{ item.opStatus }}</div>
            <div><b style="margin-right: 10px">付款方式：</b>{{ item.paymentMethod }} </div>
            <div style="text-align: right;" >
              <el-button type="success" @click="handleEdit(item)">编辑 <i class="el-icon-edit"></i></el-button>
              <el-popconfirm
                  class="ml-5"
                  confirm-button-text='确定'
                  cancel-button-text='我再想想'
                  icon="el-icon-info"
                  icon-color="red"
                  title="您确定删除吗？"
                  @confirm="del(item.id)"
              >
                <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div style="margin: 10px 0; padding: 10px; background-color: #fff">
      <el-pagination
          size="mid"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="宠物名称">
          <el-input v-model="form.animalName"></el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker clearable style="width: 80%" v-model="form.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker clearable style="width: 80%" v-model="form.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="宠物类型">
          <el-radio v-model="form.type" label="小型猫">小型猫</el-radio>
          <el-radio v-model="form.type" label="大型猫">大型猫</el-radio>
          <el-radio v-model="form.type" label="小型狗">小型狗</el-radio>
          <el-radio v-model="form.type" label="中型狗">中型狗</el-radio>
          <el-radio v-model="form.type" label="大型狗">大型狗</el-radio>
        </el-form-item>
        <el-form-item label="品种" >
          <el-input v-model="form.variety"></el-input>
        </el-form-item>
        <el-form-item label="寄养方式">
          <el-radio v-model="form.deliveryMethod" label="商家上门">商家上门</el-radio>
          <el-radio v-model="form.deliveryMethod" label="顾客自送">顾客自送</el-radio>
        </el-form-item>

        <el-form-item label="地址" v-if="form.deliveryMethod === '商家上门'">
          <el-input v-model="form.address"></el-input>
        </el-form-item>

        <el-form-item label="电话" v-if="form.deliveryMethod === '商家上门'">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>

        <el-form-item label="寄养总金额">
          <span> {{calculateTotalAmount()}}</span>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-radio v-model="form.paymentMethod" label="现金支付">现金支付</el-radio>
          <el-radio v-model="form.paymentMethod" label="银联支付">银联支付</el-radio>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MyAnimal",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5,
      name: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      animals:[],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/fosterApply/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/applcation/myAnimal").then(res => {
        this.animals = res.data
      })
    },
    calculateTotalAmount() {
      if (this.form.type && this.form.startTime && this.form.endTime) {
        const startTime = new Date(this.form.startTime).getTime();
        const endTime = new Date(this.form.endTime).getTime();
        const days = Math.ceil(Math.abs(endTime - startTime) / (24 * 60 * 60 * 1000)); // 计算天数
        let rate = 0;
        if (this.form.type==='小型猫'){
          rate =20
        }else if (this.form.type==='大型猫'){
          rate =40
        }else if (this.form.type==='小型狗'){
          rate =30
        }else if (this.form.type==='中型狗'){
          rate =50
        }else if (this.form.type==='大型狗'){
          rate =70
        }
         const totalAmount = days * rate;
         let  data = totalAmount.toFixed(2);
         this.form.totalAmount = data;
        return data;
      } else {
        return '0.00';
      }
    },
    save() {
      this.request.post("/fosterApply", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {totalAmount:10}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/fosterApply/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
